@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}

/* FrankNew Font */

@font-face {
  font-family: 'FrankNew';
  src: url('./fonts/FrankNew/FrankNew-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FrankNew';
  src: url('./fonts/FrankNew/FrankNew-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'FrankNew';
  src: url('./fonts/FrankNew/FrankNew-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FrankNew';
  src: url('./fonts/FrankNew/FrankNew-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'FrankNew';
  src: url('./fonts/FrankNew/FrankNew-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'FrankNew';
  src: url('./fonts/FrankNew/FrankNew-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'FrankNew';
  src: url('./fonts/FrankNew/FrankNew-ExtraBold.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'FrankNew';
  src: url('./fonts/FrankNew/FrankNew-ExtraBoldItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}
